import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import './ApiKeyManager.css';
import axios from 'axios';
import Modal from 'react-modal';
import ApiKeyModal from './Modal/ApiKeyModal';
import DialogOkCancelFormComponent from './Modal/DialogOkCancelFormComponent';
import { getLocaleDateTime } from '../helpers/dateTimeHelper';
import { getApiKey } from '../helpers/stringHelpers';

function ApiKeyManager({ config }) {
    const { accounts, instance } = useMsal();
    const userEmail = accounts.length > 0 ? accounts[0].username : '';

    const logmsgApiUrl = config.logMsgApiUrl;
    const logmsgApiKey = getApiKey(config.enviromentScope);

    // Init Component with UseEffect
    useEffect(() => {
        fetchAllApiKeys();
        Modal.setAppElement('#root');
    }, []); // Empty dependency array to run the effect only once when the component is mounted


    // Init States
    const [isLoading, setIsLoading] = useState(false);
    const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
    const [isEnableModalOpen, setIsEnableModalOpen] = useState(false);

    const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState("");
    const [allApiKeys, setAllApiKeys] = useState([]);

    //Default value for crating a new api key. Will reset when ApiKey has been created or modal is closed
    const [newApiKey, setNewApiKey] = useState({
        User: '',
        Description: '',
        Status: '',
        Scope: '',
        System: '',
    });

    //Init value for current selected ApiKey. Will be updated with data for the ApiKey that an action is begin performed on.
    const [currentApiKey, setCurrentApiKey] = useState({
        ID: '',
        User: '',
        Description: '',
        Status: '',
        Scope: '',
        System: '',
    });


    // Functions
    const openApiKeyModal = (type, apiKey = newApiKey) => {
        setIsApiKeyModalOpen(true);
        setModalAction(type);
        setCurrentApiKey(apiKey);
    };

    const openDisableModal = (disableApiKey) => {
        setIsDisableModalOpen(true);
        setCurrentApiKey(disableApiKey);
    };

    const openEnableModal = (enableApiKey) => {
        setIsEnableModalOpen(true);
        setCurrentApiKey(enableApiKey);
    };

    const closeModal = () => {
        setIsApiKeyModalOpen(false);
        setIsDisableModalOpen(false);
        setIsEnableModalOpen(false);
        // Reset newApiKey state when modal is closed
        setNewApiKey({
            User: '',
            Description: '',
            Status: '',
            Scope: '',
            System: '',
        });
    };

    // Get a list of all the ApiKeys from the LogMsg API
    const fetchAllApiKeys = () => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                let getRequest = logmsgApiUrl + '/GetApiKeyAll';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': logmsgApiKey
                };

                // Make the API request
                axios.get(getRequest, {
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        var apiKeysFromApi = response.data;
                        setAllApiKeys(apiKeysFromApi)
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
        };
    // Create or modify a ApiKey, based on data from modal form
    const handleChangedApiKey = (currentApiKey, action) => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                // Make the API request to insert a new API key
                let keyId = "";
                let postRequest = logmsgApiUrl;

                if (action === "Modify") {
                    keyId = 'ID=' + currentApiKey.ID + "&";
                    postRequest += '/ModifyApiKey';
                } else if (action === "Add") {
                    postRequest += '/InsertApiKey';
                }

                postRequest = postRequest + '?' + keyId + '&User=' + currentApiKey.User + '&Description=' + currentApiKey.Description
                    + '&Status=' + currentApiKey.Status + '&Scope=' + currentApiKey.Scope +
                    '&System=' + currentApiKey.System + '&Upn=' + userEmail;

                // Setup Header
                const requestHeaders = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': logmsgApiKey
                };

                axios.post(postRequest, null, { headers: requestHeaders })
                    .then((response) => {
                        // Assuming the API returns the newly added API key in the response
                        const addedApiKey = response.data;

                        // Update the apiKeys state with the newly added API key
                        setAllApiKeys([...allApiKeys, addedApiKey]);

                        // Close the modal after successful action
                        closeModal();

                        // After adding a new API key, fetch the updated list of API keys
                        fetchAllApiKeys();
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // Disabel an ApiKey
    const handleDisableResult = (result, elementId) => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
        let keyId = elementId;
        let postRequest = logmsgApiUrl + "/DisableApiKey";

        postRequest = postRequest + '?ID=' + keyId + '&Upn=' + userEmail;

                const requestHeaders = {
                    'Authorization': 'Bearer ' + accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKEY': logmsgApiKey,
        };

        axios.post(postRequest, null, { headers: requestHeaders })
            .then((response) => {
                // Close the modal after successful action
                closeModal();

                // Refetch the updated list of API keys
                fetchAllApiKeys();
            })
            .catch((error) => {
                // Handle errors
                console.log(error);
            }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // Enabel an ApiKey
    const handleEnableResult = (result, elementId) => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                let keyId = elementId;
                let postRequest = logmsgApiUrl + "/EnableApiKey";

                postRequest = postRequest + '?ID=' + keyId + '&Upn=' + userEmail;

                // Setup Header
                const requestHeaders = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': logmsgApiKey
                };


                axios.post(postRequest, null, { headers: requestHeaders })
                    .then((response) => {
                        // Close the modal after successful action
                        closeModal();

                        // Refetch the updated list of API keys
                        fetchAllApiKeys();
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    }
                );
        })
        .catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="ApiKeyManager">
            <h2>Api Key Manager</h2>
            <button onClick={() => openApiKeyModal("Add")}>Make new API Key</button>

            <table>
                <thead>
                    <tr>
                        <th>ApiKey</th>
                        <th>User</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Scope</th>
                        <th>System</th>
                        <th>StartTime</th>
                        <th>EndTime</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {allApiKeys.map((apiKey, index) => (
                        <tr key={index}>
                            <td>{apiKey.ApiKey}</td>
                            <td>{apiKey.User}</td>
                            <td>{apiKey.Description}</td>
                            <td>{apiKey.Status}</td>
                            <td>{apiKey.Scope}</td>
                            <td>{apiKey.System}</td>
                            <td>{getLocaleDateTime(apiKey.StartTime)}</td>
                            <td>{getLocaleDateTime(apiKey.EndTime)}</td>
                            <td className="td-with-buttons">
                                <button onClick={() => openApiKeyModal("Modify", apiKey)}>Modify</button>
                                {apiKey.EndTime === null ? ( // If the API key is enabled
                                    <button className="disable" onClick={() => openDisableModal(apiKey)} disabled={isLoading}>
                                        Disable ApiKey
                                    </button>
                                ) : ( // If the API key is disabled
                                    <button className="enable" onClick={() => openEnableModal(apiKey)} disabled={isLoading}>
                                        Enable ApiKey
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modals (Will only show if is{modalName}ModalOpen is true */}
            <ApiKeyModal
                isOpen={isApiKeyModalOpen}
                onRequestClose={closeModal}
                action={modalAction}
                apiKey={currentApiKey}
                onClose={closeModal}
                handleChangedApiKeyCallback={handleChangedApiKey}
            />

            <DialogOkCancelFormComponent
                isOpen={isDisableModalOpen}
                onRequestClose={closeModal}
                elementId={currentApiKey.ID}
                TextTitle="Disable ApiKey"
                TextBody1={`Do you want to disable the ApiKeyID: ${currentApiKey.ID} For: ${currentApiKey.User}`}
                TextBody2={`ApiKey: ${currentApiKey.ApiKey}`}
                TextBody3={`Description: ${currentApiKey.Description}`}
                onResult={handleDisableResult}
                onClose={closeModal}
            />

            <DialogOkCancelFormComponent
                isOpen={isEnableModalOpen}
                onRequestClose={closeModal}
                elementId={currentApiKey.ID}
                TextTitle="Enable ApiKey"
                TextBody1={`Do you want to enable the ApiKeyID: ${currentApiKey.ID} For: ${currentApiKey.User}`}
                TextBody2={`ApiKey: ${currentApiKey.ApiKey}`}
                TextBody3={`Description: ${currentApiKey.Description}`}
                onResult={handleEnableResult}
                onClose={closeModal}
            />
        </div>
    );
};

export default ApiKeyManager;