import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Modal.css';

function AlertTypeModal({ isOpen, onRequestClose, action, alertType, onClose, error, handleChangedAlertTypeCallback }) {
    const [editAlertType, setEditAlertType] = useState(alertType);


    useEffect(() => {
        Modal.setAppElement('#root');
    }, []); // Empty dependency array to run the effect only once when the component is mounted

    useEffect(() => {
        // Update the internal state when the editingAlertType prop changes
        setEditAlertType(alertType);
    }, [alertType]);

    return (
        <div>
            {/* Modal for adding or modifiying AlertType */}
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
            >

                <h3>Fill in the AlertType details to {action === "Add" ? "add:" : "modify"}</h3>

                <h4>{error !== null ? error : null}</h4>

                <label>Alert Type: </label>
                <input
                    type="text"
                    value={editAlertType.alertType}
                    onChange={(e) => setEditAlertType({ ...editAlertType, alertType: e.target.value })}
                />
                <label>Alert Value: </label>
                <input
                    type="text"
                    value={editAlertType.alertValue}
                    onChange={(e) => setEditAlertType({ ...editAlertType, alertValue: e.target.value })}
                />
                <label>Description: </label>
                <input
                    type="text"
                    value={editAlertType.description}
                    onChange={(e) => setEditAlertType({ ...editAlertType, description: e.target.value })}
                />
                <label>Status: </label>
                <input
                    type="text"
                    value={editAlertType.status}
                    onChange={(e) => setEditAlertType({ ...editAlertType, status: e.target.value })}
                />
                <label>TimeStamp: </label>
                <input
                    type="text"
                    value={editAlertType.timeStamp}
                    onChange={(e) => setEditAlertType({ ...editAlertType, timeStamp: e.target.value })}
                />

                <button onClick={() => handleChangedAlertTypeCallback(editAlertType, action)}>{action === "Add" ? "Add New AlertType" : "Modify AlertType"}</button>
                <button class="cancel" onClick={onClose}>Cancel</button>
            </Modal>
        </div>
    );
};

export default AlertTypeModal;