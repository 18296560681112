import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faShare } from '@fortawesome/free-solid-svg-icons';
import { viewFile, downloadFile, verifyCanResendFile } from '../../helpers/fileHelper';

const FileTableCell = ({ row, config, style, openModal, onResendFile, accounts, instance }) => {
    const cellStyle = { maxWidth: "50px", ...style };

    const handleResendClick = () => {
        verifyCanResendFile(row, config, openModal, onResendFile, accounts, instance);
    };

    return (
        <td style={cellStyle}>
            {row.fileName}
            {row.fileName ?
                <div>
                    <FontAwesomeIcon
                        title="View file"
                        className="filter-icon"
                        icon={faEye}
                        onClick={() => viewFile(row.id, row.fileName, config, accounts, instance)} />
                    <FontAwesomeIcon
                        title="Download file"
                        className="filter-icon"
                        icon={faDownload}
                        onClick={() => downloadFile(row.id, row.fileName, config, accounts, instance)} />
                    <FontAwesomeIcon
                        title="Resend message"
                        className="filter-icon"
                        icon={faShare}
                        onClick={handleResendClick} />
                </div> : null}
        </td>
    );
};

export default FileTableCell;