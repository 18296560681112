import { z } from 'zod';

export const AppSettingsSchema = z.object({
    clientId: z.string(),
    logMsgApiUrl: z.string(),
    authorisedGroups: z.string(),
    color: z.string(),
    redirectUri: z.string(),
    devUrl: z.string(),
    qaUrl: z.string(),
    prodUrl: z.string(),
    enviromentScope: z.string()
});