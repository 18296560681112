import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';

const DateFilter = ({ selectedDate, handleDateChange, toggleDatePicker, label }) => {
    const handleClick = () => {
        if (toggleDatePicker) {
            toggleDatePicker(`${label.toLowerCase()}-datepicker`);
        }
    };

    return (
        <div className="filter-item">
            <label htmlFor={label}>{label}:</label>
            <DatePicker
                id={`${label.toLowerCase()}-datepicker`}
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                calendarStartDay={1}
                showPopperArrow={false}
                popperPlacement="bottom-start"
                popperModifiers={{
                    offset: {
                        enabled: true,
                        offset: '5px, 10px'
                    },
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport'
                    }
                }}
            />
            <FontAwesomeIcon
                className="filter-icon"
                icon={faCalendar}
                onClick={handleClick}
            />
        </div>
    );
};

export default DateFilter;
