import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

const LoginPage = (path) => {
    const { accounts, instance, inProgress } = useMsal();
    const navigate = useNavigate();

    useEffect(() => {
        const handleRedirect = async () => {

            if (inProgress === "none") {
                if (accounts.length > 0) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const returnUrl = urlParams.get('returnUrl');

                    navigate(returnUrl);
                } else {
                    try {
                        await instance.loginRedirect();
                    } catch {
                        console.log("Error catch");
                    }
                }
            }
        };

        handleRedirect();
    }, [accounts, instance, inProgress, navigate]);

    return <div>Redirecting to login...</div>;
};

export default LoginPage;
