import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Modal.css';

function ApiKeyModal({ isOpen, onRequestClose, action, apiKey, onClose, handleChangedApiKeyCallback }) {
    const [editApiKey, setEditApiKey] = useState(apiKey);

    useEffect(() => {
        Modal.setAppElement('#root');
    }, []); // Empty dependency array to run the effect only once when the component is mounteds

    useEffect(() => {
        // Update the internal state when the editingApiKey prop changes
        setEditApiKey(apiKey);
    }, [apiKey]);

    return (
        <div>
            {/* Modal for adding a new API key */}
            {/*<h2>Hello from Modal Component {isOpen}, {onRequestClose}, {action}, {apiKey.ID}, {onClose}</h2>*/}
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
            >
                <h3>{ action === "Add" ? "Add New API Key" : "Modify API Key"}</h3>
                <label>User: </label>
                <input
                    type="text"
                    value={editApiKey.User}
                    onChange={(e) => setEditApiKey({ ...editApiKey, User: e.target.value })}
                />
                <label>Description: </label>
                <input
                    type="text"
                    value={editApiKey.Description}
                    onChange={(e) => setEditApiKey({ ...editApiKey, Description: e.target.value })}
                />
                <label>Status: </label>
                <input
                    type="text"
                    value={editApiKey.Status}
                    onChange={(e) => setEditApiKey({ ...editApiKey, Status: e.target.value })}
                />
                <label>Scope: </label>
                <input
                    type="text"
                    value={editApiKey.Scope}
                    onChange={(e) => setEditApiKey({ ...editApiKey, Scope: e.target.value })}
                />
                <label>System: </label>
                <input
                    type="text"
                    value={editApiKey.System}
                    onChange={(e) => setEditApiKey({ ...editApiKey, System: e.target.value })}
                />

                <button onClick={() => handleChangedApiKeyCallback(editApiKey, action)}>{action === "Add" ? "Add New API Key" : "Modify API Key"}</button>
                <button class="cancel" onClick={onClose}>Cancel</button>
            </Modal>
        </div>
    );
};

export default ApiKeyModal;