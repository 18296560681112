import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import './index.css';
import App from './App';
import axios from 'axios';
import { AppSettingsSchema } from './schemas/appSettingsSchema';
import createMsalInstance from './services/msalInstance';

// Fetch appsettings.json to get settings
axios.get('/assets/appsetting.json')
    .then(response => {
        try {
            const settings = AppSettingsSchema.parse(response.data);

            // Create MSAL instance with the clientId from settings
            const msalInstance = createMsalInstance(settings.clientId, settings.redirectUri);

            // Render the application with MSAL instance
            renderApp(msalInstance);
        } catch (error) {
            console.error('Error loading page', error);
        }
    })
    .catch(error => {
        console.error('Error loading page:', error);
    });

// Function to render the application with MSAL instance
function renderApp(msalInstance) {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </React.StrictMode>
    );
}