import { PublicClientApplication } from '@azure/msal-browser';

const createMsalInstance = (clientId, redirectUri) => {
    const msalConfig = {
        auth: {
            clientId: clientId,
            authority: 'https://login.microsoftonline.com/odfjell.onmicrosoft.com',
            redirectUri: redirectUri,
            postLogoutRedirectUri: redirectUri + "logout"
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
        },
    };

    return new PublicClientApplication(msalConfig);
};

export default createMsalInstance;