// Navbar.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';
import { useMsal } from '@azure/msal-react';
import LogoutButton from '../LogoutButton';

function Navbar({ config }) {
    const { accounts } = useMsal();
    const location = useLocation();

    return (
        <div>
            {(accounts.length > 0) && (<React.Fragment>
                <nav>
                    <ul className="left-nav">
                        <li>
                            <NavLink to="/" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>Home</NavLink>
                        </li>
                        <li><NavLink to="/tokenmanager" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>Token Manager</NavLink></li>
                        <li><NavLink to="/profile" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>Profile</NavLink></li>
                        <li><NavLink to="/messagelogs" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>View Message Logs</NavLink></li>
                        <li><NavLink to="/uniqerrormsg" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>View Uniq Error Logs</NavLink></li>
                        <li><NavLink to="/apikeymanager" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>ApiKey Manager</NavLink></li>
                        <li><NavLink to="/alertlogs" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>Alert Logs</NavLink></li>
                        <li><NavLink to="/alertconfig" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>Alert Config</NavLink></li>
                        <li><NavLink to="/messagereport" activeclassname="active" style={{ color: 'white', textDecoration: 'none' }}>Message Report</NavLink></li>
                    </ul>
                    <ul className="right-nav">
                        <li>
                            <a href={config.devUrl + location.pathname} className={config.enviromentScope === 'dev' ? 'highlighted' : ''} style={{ color: 'lightblue', marginLeft: '10px', textDecoration: 'none' }}>DEV</a>
                        </li>
                        <li>
                            <a href={config.qaUrl + location.pathname} className={config.enviromentScope === 'qa' ? 'highlighted' : ''} style={{ color: 'orange', marginLeft: '10px', textDecoration: 'none' }}>QA</a>
                        </li>
                        <li>
                            <a href={config.prodUrl + location.pathname} className={config.enviromentScope === 'prod' ? 'highlighted' : ''} style={{ color: 'lightgreen', marginLeft: '10px', textDecoration: 'none' }}>PROD</a>
                        </li>

                        {accounts.length === 0 ? (
                            <li></li>
                        ) : (
                            <li><LogoutButton></LogoutButton></li>
                        )}
                    </ul>
                </nav>
            </React.Fragment>)}
        </div>
    );
}

export default Navbar;
