import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './Modal.css';

function DialogOkCancelFormComponent({ isOpen, onRequestClose, elementId, TextTitle, TextBody1,
    TextBody2, TextBody3, onResult, onClose }) {

    //Handle Ok button
    const handleOk = () => {
        onResult(true, elementId);
    };

    useEffect(() => {
        Modal.setAppElement('#root');
    }, []); // Empty dependency array to run the effect only once when the component is mounteds


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <h2>{TextTitle}</h2>
            <p>{TextBody1}</p>
            <p>{TextBody2}</p>
            <p>{TextBody3}</p>
            <button onClick={handleOk}>OK</button>
            <button class="cancel" onClick={onClose}>Cancel</button>
        </Modal>
    );
}

export default DialogOkCancelFormComponent;
