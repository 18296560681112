import React from 'react';
import { useMsal } from '@azure/msal-react';

const Profile = () => {
    const { accounts } = useMsal();

    const userEmail = accounts.length > 0 ? accounts[0].username : '';

    return (
        <div>
            <h1>User Profile</h1>
            <p>Email Address: {userEmail}</p>
        </div>
    );
};

export default Profile;
