import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './MessageLogs.css';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import StatusTableCell from './TableCellComponents/StatusTableCell';
import FilterBar from './FilterComponents/FilterBar';
import { getLocaleDateTime, getYesterday, getTomorrow } from '../helpers/dateTimeHelper';
import { getApiKey } from '../helpers/stringHelpers';

function AlertLogs({ config }) {
    //Init states
    const [logMessageTable, setLogMessageTable] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [filterWords, setFilterWords] = useState('');
    const [dateFrom, setDateFrom] = useState(getYesterday());
    const [dateTo, setDateTo] = useState(getTomorrow());

    const maxRows = 100;
    const { accounts, instance } = useMsal();


    // Handle filter changes
    const handleInputChange = (e) => {
        // Update the inputValue state when the input value changes
        setInputValue(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            setFilterWords(e.target.value);
        }
    };

    const handleSearchClick = () => {
        setFilterWords(inputValue);
    };

    // Date Picker changes
    const handleDateFromChange = (selectedDate) => {
        setDateFrom(selectedDate);
    };

    const handleDateToChange = (selectedDate) => {
        setDateTo(selectedDate);
    };

    const toggleDatePicker = (id) => {
        const datePicker = document.getElementById(id);
        if (datePicker) {
            datePicker.focus();
        }
    };
    
    // Find all alert messages from API, based on filters: word and dates.
    // If any of these filter changes this useEffect should run
    useEffect(() => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                // Get config values

                const logmsgApiUrl = config.logMsgApiUrl;
                const apiKey = getApiKey(config.enviromentScope);

                // Setup GET query
                let getRequest = logmsgApiUrl + '/AlertLogGetAll?';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': apiKey
                };

                // Update Params based on state of the filters
                const updateQueryParams = () => {
                    const dtFrom = dateFrom.toISOString().split('T')[0];
                    const dtTo = dateTo.toISOString().split('T')[0];
                    return { dtFrom, dtTo, maxRows: maxRows };
                };
                const queryParams = updateQueryParams();

                // Make the API request
                axios.get(getRequest, {
                    params: queryParams,
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        if (!response.data || response.data.length === 0) {
                            // Handle the case when response data is empty
                            setLogMessageTable([]);
                        } else {
                            let filteredMessages = response.data;
                            if (filterWords.trim() !== "") {
                                // Filter messages based on input value if filter is not empty
                                filteredMessages = response.data.filter(message => {
                                    const messageText = message.message.toLowerCase();
                                    const alertNameText = message.alertNameUnique.toLowerCase();
                                    const statusText = message.status.toLowerCase();
                                    const filterWordsArray = filterWords.toLowerCase().split(' ');

                                    // Check if all words in filterWords are present in any of the fields
                                    return filterWordsArray.every(word =>
                                        messageText.includes(word) || alertNameText.includes(word) || statusText.includes(word)
                                    );
                                });
                            }
                            // Set the fetched data to the logMessageTable variable
                            setLogMessageTable(filteredMessages);
                        }
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [dateFrom, dateTo, filterWords]); // Use dependency array to run the effect only when at least one of these values are changed

    return (
        <div className="message-logs-container">
            <h2 className="message-logs-title">Alert Logs</h2>

            {/* Filter inputs in a horizontal line */}
            <FilterBar
                config={config}
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                handleInputKeyPress={handleInputKeyPress}
                handleSearchClick={handleSearchClick}
                dateFrom={dateFrom}
                handleDateFromChange={handleDateFromChange}
                toggleDatePicker={toggleDatePicker}
                dateTo={dateTo}
                handleDateToChange={handleDateToChange}
            />

            {accounts.length === 0 ? (
                "Please log in to see content"
            ) : (
                <div className="table-container">
                    <table className="message-logs-table">
                        <thead>
                            <tr>
                                <th style={{ maxWidth: "60px" }}>Processed</th>
                                <th style={{ maxWidth: "70px" }}>Alert Name</th>
                                <th style={{ maxWidth: "400px" }}>Message</th>
                                <th style={{ maxWidth: "30px" }}>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {logMessageTable.map((row, index) => (
                                <tr key={index}>
                                    <td style={{ maxWidth: "60px" }}>{getLocaleDateTime(row.timeStamp)}</td>
                                    <td style={{ maxWidth: "70px" }}>{row.alertNameUnique}</td>
                                    <td style={{ maxWidth: "400px" }}>{row.message}</td>
                                    <StatusTableCell status={row.status} style={{ maxWidth: "30px" }} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default AlertLogs;
