import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const DropdownTableHeader = ({ title, style, loadingDropdownOptions, selectedValue, options, handleDropdownChange }) => {
    return (
        <th style={{ ...style, maxWidth: "80px" }} className="header-with-dropdown">
            <div>
                <span>{title}</span>
            </div>
            <div style={{ position: 'relative' }}>
                {loadingDropdownOptions ? (
                    <div className="dropdown-spinner-container">
                        <FontAwesomeIcon icon={faSpinner} className="dropdown-spinner-icon" spin />
                    </div>
                ) : (
                    <select className="dropdown" value={selectedValue} onChange={(e) => handleDropdownChange(e)}>
                        <option key="All" value="All">All</option>
                        {options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                )}
            </div>
        </th>
    );
};

export default DropdownTableHeader;
