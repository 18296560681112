// SanitizedTableCell.js
import React from 'react';
import DOMPurify from 'dompurify';
import { sanitizedHtmlLinks } from '../../helpers/stringHelpers';

const SanitizedTableCell = ({ stringValue, style }) => {
    // Sanitize the HTML, allowing href and target attributes
    const sanitizedLinks = DOMPurify.sanitize(sanitizedHtmlLinks(stringValue), {
        ALLOWED_TAGS: ['a'],
        ALLOWED_ATTR: ['href']
    });

    // Dynamically create the style object with maxWidth if provided in the props
    const cellStyle = {
        ...style, // Include other styles provided in props
        maxWidth: style && style.maxWidth ? style.maxWidth : "400px" // Default maxWidth is 400px
    };

    // Add target="_blank" to <a> tags. This will open link in new tab if clicked on
    const addTargetBlank = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const links = doc.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
        });
        return doc.documentElement.outerHTML;
    };

    // Add target="_blank" to all <a> tags
    const sanitizedHtmlWithTargetBlank = addTargetBlank(sanitizedLinks);

    return (
        <td style={cellStyle} dangerouslySetInnerHTML={{ __html: sanitizedHtmlWithTargetBlank }} />
    );
};

export default SanitizedTableCell;
