import React from 'react';

const StatusTableCell = ({ status, style }) => {
    const cellStyle = { maxWidth: "50px", ...style };

    const textColor = status === 'Ok' ? 'green-text' : 'red-text';

    return (
        <td style={cellStyle} className={textColor}>
            {status}
        </td>
    );
};

export default StatusTableCell;