import moment from 'moment';

// Convert the UPC time to a locale Date object
export const getLocaleDateTime = (utcTime) => {
    if (utcTime === null || utcTime === "") {
        return utcTime;
    }

    const date = moment.utc(utcTime);

    // Convert to local time
    const localTime = date.local().format('YYYY-MM-DD HH:mm:ss');

    return localTime;
}

export function getYesterday() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
}

export function getTomorrow() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
}