import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import './AlertConfig.css';
import axios from 'axios';
import Modal from 'react-modal';
import AlertConfigModal from './Modal/AlertConfigModal';
import AlertTypeModal from './Modal/AlertTypeModal';
import StatusTableCell from './TableCellComponents/StatusTableCell';
import { getLocaleDateTime } from '../helpers/dateTimeHelper';
import { getApiKey } from '../helpers/stringHelpers';

function AlertConfig({ config }) {
    const { accounts, instance } = useMsal();

    const userEmail = accounts.length > 0 ? accounts[0].username : '';
    const logmsgApiUrl = config.logMsgApiUrl;
    const logmsgApiKey = getApiKey(config.enviromentScope);

    // Init Component with UseEffect
    useEffect(() => {
        fetchAllAlertConfigs();
        fetchAllAlertTypes();
        Modal.setAppElement('#root');
    }, []); // Empty dependency array to run the effect only once when the component is mounted

    // Init States
    const [error, setError] = useState(null);
    const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
    const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState("");

    const [allAlertConfigs, setAllAlertConfigs] = useState([]);
    const [newAlertConfig, setNewAlertConfig] = useState({
        alertNameUnique: 'RuleName',
        alertType: 'topdesk',
        alertFrequencyInMin: '1440',
        alertSendMaxMsgs: '1',
        alertStartTime: '2024-02-29T12:24:28.571Z',
        alertEndTime: '',
        alertFilter: "[transactionType] ='Employee' and [status]='Error'",
        description: 'My new Alert',
        alternative: '',
        lastRunStatus: '',
        lastRun: '',
        Upn: ''
    });
    const [currentAlertConfig, setCurrentAlertConfig] = useState({
        ID: '',
        alertNameUnique: '',
        alertType: '',
        alertFrequencyInMin: '',
        alertSendMaxMsgs: '',
        alertStartTime: '',
        alertEndTime: '',
        alertFilter: '',
        description: '',
        alternative: '',
        lastRunStatus: '',
        lastRun: '',
        Upn: ''
    });

    const [allAlertTypes, setAllAlertTypes] = useState([]);
    const [newAlertType, setNewAlertType] = useState({
        alertType: 'topdesk',
        alertValue: 'topdesk',
        description: 'Send to Topdesk',
        status: 'Ok',
        timeStamp: '2024-04-25T08:42:14.679Z'
    });
    const [currentAlertType, setCurrentAlertType] = useState({
        alertType: '',
        alertValue: '',
        description: '',
        status: '',
        timeStamp: ''
    });


    // Functions

    // Open Add/Modify Alert Config Modal
    const openConfigModal = (type, alertConfig = newAlertConfig) => {
        setCurrentAlertConfig(alertConfig);
        setIsConfigModalOpen(true);
        setModalAction(type);
    };

    // Close Add/Modify Alert Config Modal
    const closeConfigModal = () => {
        setIsConfigModalOpen(false);
        setError(null);
        // Reset newAlertConfig state when modal is closed (will be ready for next add)
        setNewAlertConfig({
            alertNameUnique: 'RuleName',
            alertType: 'topdesk',
            alertFrequencyInMin: '1440',
            alertSendMaxMsgs: '1',
            alertStartTime: '2024-02-29T12:24:28.571Z',
            alertEndTime: '',
            alertFilter: "[transactionType] ='Employee' and [status]='Error'",
            description: 'My new Alert',
            alternative: '',
            lastRunStatus: '',
            lastRun: ''
        });
    };

    // Open Add/Modify Alert Type Modal
    const openTypeModal = (type, alertType = newAlertType) => {
        setCurrentAlertType(alertType);
        setIsTypeModalOpen(true);
        setModalAction(type);
    };

    // Close Add/Modify Alert Config Modal
    const closeTypeModal = () => {
        setIsTypeModalOpen(false);
        setError(null);
        // Reset newAlertType state when modal is closed (will be ready for next add)
        setNewAlertType({
            alertType: 'topdesk',
            alertValue: 'topdesk',
            description: 'Send to Topdesk',
            status: 'Ok',
            timeStamp: '2024-04-25T08:42:14.679Z'
        });
    };

    // Get a list of all the AlertConfigs from the LogMsg API
    const fetchAllAlertConfigs = () => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                let getRequest = logmsgApiUrl + '/AlertConfigGetAll';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': logmsgApiKey
                };


                // Make the API request
                axios.get(getRequest, {
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        var alertConfigsFromApi = response.data;
                        setAllAlertConfigs(alertConfigsFromApi)
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // Get a list of all the AlertTypes from the LogMsg API
    const fetchAllAlertTypes = () => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                let getRequest = logmsgApiUrl + '/AlertTypeGetAll';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': logmsgApiKey
                };

                // Make the API request
                axios.get(getRequest, {
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        var alertTypesFromApi = response.data;
                        setAllAlertTypes(alertTypesFromApi)
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangedAlertConfig = (currentAlertConfig, action) => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {

                let accessToken = accessTokenResponse.accessToken;

            // Make the API request to insert or modify a AlertConfig
            let postRequest = logmsgApiUrl;

            if (action === "Modify") {
                postRequest += '/AlertConfigModify';
            } else if (action === "Add") {
                postRequest += '/AlertConfigInsert';
            }

            // Set parameter for post request
            const params = {
                ID: currentAlertConfig.ID,
                alertNameUnique: currentAlertConfig.alertNameUnique,
                alertType: currentAlertConfig.alertType,
                alertFrequencyInMin: currentAlertConfig.alertFrequencyInMin,
                alertSendMaxMsgs: currentAlertConfig.alertSendMaxMsgs,
                alertStartTime: currentAlertConfig.alertStartTime,
                alertFilter: currentAlertConfig.alertFilter,
                description: currentAlertConfig.description,
                alternative: currentAlertConfig.alternative,
                lastRunStatus: currentAlertConfig.lastRunStatus,
                Upn: userEmail
            };

            // Add additional parameters if there is a endtime
            if (currentAlertConfig.alertEndTime !== null) {
                params.alertEndTime = currentAlertConfig.alertEndTime;
                params.lastRun = currentAlertConfig.lastRun;
            }

                    const requestHeaders = {
                        'Authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKEY': logmsgApiKey,
            };

            // Post newAlertConfig to LogMsgAPI
            axios.post(postRequest, null, { headers: requestHeaders, params: params })
                .then((response) => {
                    // Get newly added/modified AlertConfig as resonse
                    const addedAlertConfig = response.data;

                    // Update the allAlertCondfig with state with the created/updated AlertConfig 
                    setAllAlertConfigs([...allAlertConfigs, addedAlertConfig]);

                    // Close the modal
                    closeConfigModal();

                    // Fetch the updated list of AlertConfig
                    fetchAllAlertConfigs();
                })
                .catch((exc) => {
                    // Handle errors
                    console.log(exc);
                    setError(exc.response.data.Error);
                }
                    );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChangedAlertType = (currentAlertType, action) => {
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;

                // Make the API request to insert or modify a AlertType
                let postRequest = logmsgApiUrl;

                if (action === "Modify") {
                    postRequest += '/AlertTypeModify';
                } else if (action === "Add") {
                    postRequest += '/AlertTypeInsert';
                }

                // Set parameter for post request
                const params = {
                    ID: currentAlertType.ID,
                    alertType: currentAlertType.alertType,
                    alertValue: currentAlertType.alertValue,
                    description: currentAlertType.description,
                    status: currentAlertType.status,
                    timeStamp: currentAlertType.timeStamp
                };

                const requestHeaders = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': logmsgApiKey,
                };

                // Post newAlertType to LogMsgAPI
                axios.post(postRequest, null, { headers: requestHeaders, params: params })
                    .then((response) => {
                        // Get newly added/modified AlertType as resonse
                        const addedAlertType = response.data;

                        // Update the allAlertCondfig with state with the created/updated AlertType
                        setAllAlertTypes([...allAlertTypes, addedAlertType]);

                        // Close the modal
                        closeTypeModal();

                        // Fetch the updated list of AlertType
                        fetchAllAlertTypes();
                    })
                    .catch((exc) => {
                        // Handle errors
                        console.log(exc);
                        setError(exc.response.data.Error);
                    }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="AlertConfig">
            <div className="AlertConfigs">
                <h2>AlertConfigs</h2>
                <p>Here you can manage your AlertConfigs.</p>
                <p>
                    You can filter on one or more of [sender], [receiver], [transactionType], [messageType], [transactionTag], [description], [fileName] and [status] with = or like '%text%'
                    The 'alternative body' is used when you want a diffrent text than the message alert description gives. They can include variable substitute of filter values as written above eks. [transactionTag].
                    The message filter will start from 'lastRun' to up to current scheduled job every 5 min. Modify 'lastRun' to force new collection.
                </p>

                <button onClick={() => openConfigModal("Add")}>Make new AlertConfig</button>

                <table>
                    <thead>
                        <tr>
                            <th style={{ maxWidth: "40px" }}>Rule Name</th>
                            <th style={{ maxWidth: "40px" }}>Alert Type</th>
                            <th style={{ maxWidth: "60px" }}>Frequency in min</th>
                            <th style={{ maxWidth: "60px" }}>Min Msg Count</th>
                            <th style={{ maxWidth: "60px" }}>Rule start time</th>
                            <th style={{ maxWidth: "60px" }}>Rule end time</th>
                            <th style={{ maxWidth: "60px" }}>Query Filter</th>
                            <th style={{ maxWidth: "60px" }}>Mail: Subject / <br />TopDesk: Brief description</th>
                            <th style={{ maxWidth: "60px" }}>Alternative body</th>
                            <th style={{ maxWidth: "60px" }}>Last run status</th>
                            <th style={{ maxWidth: "60px" }}>Rule last runtime</th>
                            <th style={{ maxWidth: "60px" }}>Last modified by</th>
                            <th style={{ maxWidth: "60px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allAlertConfigs.map((alertConfig, index) => (
                            <tr key={index}>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.alertNameUnique}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.alertType}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.alertFrequencyInMin}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.alertSendMaxMsgs}</td>
                                <td style={{ maxWidth: "40px" }}>{getLocaleDateTime(alertConfig.alertStartTime)}</td>
                                <td style={{ maxWidth: "40px" }}>{getLocaleDateTime(alertConfig.alertEndTime)}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.alertFilter}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.description}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.alternative}</td>
                                <StatusTableCell status={alertConfig.lastRunStatus} style={{ maxWidth: "40px" }} />
                                <td style={{ maxWidth: "40px" }}>{getLocaleDateTime(alertConfig.lastRun)}</td>
                                <td style={{ maxWidth: "40px" }}>{alertConfig.upn}</td>
                                <td style={{ maxWidth: "40px" }}>
                                    <button onClick={() => openConfigModal("Modify", alertConfig)}>Modify</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Modal for adding or modify AlertConfig */}
                <AlertConfigModal
                    isOpen={isConfigModalOpen}
                    onRequestClose={closeConfigModal}
                    action={modalAction}
                    alertConfig={currentAlertConfig}
                    onClose={closeConfigModal}
                    error={error}
                    handleChangedAlertConfigCallback={handleChangedAlertConfig}
                />
            </div>

            <div className="AlertType">
                <h2>AlertTypes</h2>
                <p>
                    Here you can manage your AlertTypes. AlertType must start with 'mail_' or 'topdesk_'
                    Mail addresses can be seperated with ';'
                    If the Alert-Value of 'topdesk_' ends with '@odfjell.com' the user must exist in TopDesk. The user object will be used as Caller and will recieve an Incident Mail.
                    All mail will come from 'Helpdesk' and Helpdesk will have a copy of the mail in the 'Sendt'' folder.
                </p>

                <button onClick={() => openTypeModal("Add")}>Make new AlertType</button>

                <table>
                    <thead>
                        <tr>
                            <th style={{ maxWidth: "40px" }}>Alert Type</th>
                            <th style={{ maxWidth: "40px" }}>Alert Value/Reciver</th>
                            <th style={{ maxWidth: "60px" }}>Description</th>
                            <th style={{ maxWidth: "60px" }}>Status</th>
                            <th style={{ maxWidth: "60px" }}>TimeStamp</th>
                            <th style={{ maxWidth: "60px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allAlertTypes.map((alertType, index) => (
                            <tr key={index}>
                                <td style={{ maxWidth: "40px" }}>{alertType.alertType}</td>
                                <td style={{ maxWidth: "40px" }}>{alertType.alertValue}</td>
                                <td style={{ maxWidth: "40px" }}>{alertType.description}</td>
                                <StatusTableCell status={alertType.status} style={{ maxWidth: "40px" }} />
                                <td style={{ maxWidth: "40px" }}>{getLocaleDateTime(alertType.timeStamp)}</td>

                                <td style={{ maxWidth: "40px" }}>
                                    <button onClick={() => openTypeModal("Modify", alertType)}>Modify</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Modal for adding or modifying AlertType */}
                <AlertTypeModal
                    isOpen={isTypeModalOpen}
                    onRequestClose={closeTypeModal}
                    action={modalAction}
                    alertType={currentAlertType}
                    onClose={closeTypeModal}
                    error={error}
                    handleChangedAlertTypeCallback={handleChangedAlertType}
                />
            </div>
        </div>
    );
}

export default AlertConfig;
