// Preprocesses the description to ensure absolute URLs
export const sanitizedHtmlLinks = (stringValue) => {
    if (typeof stringValue !== 'string' || !stringValue) {
        return stringValue; // Return the original value if it's not a string or is empty
    }

    // Regular expression to check if the URL is absolute
    const absoluteUrlRegex = /^(?:https?:\/\/)/i;

    // Check if the description contains an <a> tag with an href attribute
    const matches = stringValue.match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi);
    if (matches) {
        // Preprocess each match
        matches.forEach(match => {
            let href = match.match(/href=(["'])(.*?)\1/i)[2];
            const trimmedHref = href.trim();

            // If the href is not an absolute URL, prepend "http://" to make it absolute
            if (!absoluteUrlRegex.test(trimmedHref)) {
                const absoluteHref = `http://${trimmedHref}`;
                stringValue = stringValue.replace(href, absoluteHref);
            } else {
                stringValue = stringValue.replace(href, trimmedHref);
            }
        });
    }

    return stringValue;
};

export const getApiKey = (enviromentScope) => {
    let apiKey = "";

    if (enviromentScope === "dev") {
        apiKey = process.env.REACT_APP_API_KEY_DEV;
    } else if (enviromentScope === "qa") {
        apiKey = process.env.REACT_APP_API_KEY_QA;
    } else if (enviromentScope === "prod") {
        apiKey = process.env.REACT_APP_API_KEY_PROD;
    }

    return apiKey;
}
