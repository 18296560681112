import React, { useState, useEffect } from 'react';
import './MessageLogs.css';
import axios from 'axios';
import StatusTableCell from './TableCellComponents/StatusTableCell';
import { getLocaleDateTime } from '../helpers/dateTimeHelper';
import { getApiKey } from '../helpers/stringHelpers';
import { useMsal } from '@azure/msal-react';

function MessageReport({ config }) {
    const { accounts, instance } = useMsal();

    //Init states
    const [logMessageTable, setLogMessageTable] = useState([]);
    const [daysInputValue, setDaysInputValue] = useState(5);
    const [typeInputValue, setTypeInputValue] = useState(7);
    const [statusInputValue, setStatusInputValue] = useState("Error");

    const [daysBack, setDaysBack] = useState(5);
    const [type, setType] = useState(7);
    const [status, setStatus] = useState("Error");

    // Handle filter changes
    const handleDaysChange = (e) => {
        // Update the inputValue state when the input value changes
        setDaysInputValue(e.target.value);
    };

    const handleTypeChange = (e) => {
        // Update the inputValue state when the input value changes
        setTypeInputValue(e.target.value);
    };

    const handleStatusChange = (e) => {
        // Update the inputValue state when the input value changes
        setStatusInputValue(e.target.value);
    };

    const handleInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            setDaysBack(daysInputValue);
            setType(typeInputValue);
            setStatus(statusInputValue);
        }
    };
    
    // Find all alert messages from API, based on filters: word and dates.
    // If any of these filter changes this useEffect should run
    useEffect(() => {
        // Get config values
        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                // Get config values

                const logmsgApiUrl = config.logMsgApiUrl;
                const apiKey = getApiKey(config.enviromentScope);

                // Setup GET query
                let getRequest = logmsgApiUrl + '/LogsGetGroup?';

                // Setup Header
                const headers = {
                    'Authorization': 'Bearer ' + accessToken,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKEY': apiKey
                };

                // Update Params based on state of the filters
                const updateQueryParams = () => {
                    return { days: daysBack, type: type, status: status };
                };
                const queryParams = updateQueryParams();

                // Make the API request
                axios.get(getRequest, {
                    params: queryParams,
                    headers: headers
                })
                    .then((response) => {
                        // Set the fetched data to the logMessageTable variable
                        if (!response.data || response.data.length === 0) {
                            // Handle the case when response data is empty
                            setLogMessageTable([]);
                        } else {
                            // Set the fetched data to the logMessageTable variable
                            setLogMessageTable(response.data);
                        }
                    })
                    .catch((error) => {
                        // Handle errors
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [daysBack, type, status]); // Use dependency array to run the effect only when at least one of these values are changed

    return (
        <div className="message-logs-container">
            <h2 className="message-logs-title">Message Report</h2>

            {/* Filter inputs in a horizontal line */}
            <div className="filter-container" style={{ backgroundColor: config.color }}>
                <div className="filter-item">
                    <label htmlFor="text-filter">Days Back:</label>
                    <div>
                        <input className="filter-input"
                            type="number"
                            id="filter-days"
                            value={daysInputValue}
                            onChange={handleDaysChange}
                            onKeyPress={handleInputKeyPress}
                        />
                    </div>
                </div>
                <div className="filter-item">
                    <label htmlFor="text-filter">Type:</label>
                    <div>
                        <input className="filter-input"
                            type="number"
                            id="filter-type"
                            value={typeInputValue}
                            onChange={handleTypeChange}
                            onKeyPress={handleInputKeyPress}
                        />
                    </div>
                </div>
                <div className="filter-item">
                    <label htmlFor="text-filter">Status:</label>
                    <div>
                        <input className="filter-input"
                            type="text"
                            id="filter-status"
                            value={statusInputValue}
                            onChange={handleStatusChange}
                            onKeyPress={handleInputKeyPress}
                        />
                    </div>
                </div>
            </div>

            
                <div className="table-container">
                    <table className="message-logs-table">
                        <thead>
                            <tr>
                                <th style={{ maxWidth: "30px" }}>Count</th>
                                <th style={{ maxWidth: "30px" }}>Date</th>
                                <th style={{ maxWidth: "30px" }}>Sender</th>
                                <th style={{ maxWidth: "30px" }}>Reciver</th>
                                <th style={{ maxWidth: "30px" }}>Transaction Type</th>
                                <th style={{ maxWidth: "30px" }}>Message Type</th>
                                <th style={{ maxWidth: "30px" }}>Status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {logMessageTable.map((row, index) => (
                                <tr key={index}>
                                    <td style={{ maxWidth: "30px" }}>{row.count}</td>
                                    <td style={{ maxWidth: "30px" }}>{getLocaleDateTime(row.date)}</td>
                                    <td style={{ maxWidth: "30px" }}>{row.sender}</td>
                                    <td style={{ maxWidth: "30px" }}>{row.receiver}</td>
                                    <td style={{ maxWidth: "30px" }}>{row.transactionType}</td>
                                    <td style={{ maxWidth: "30px" }}>{row.messageType}</td>
                                    <StatusTableCell status={row.status} style={{ maxWidth: "30px" }} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </div>
    );
}

export default MessageReport;
