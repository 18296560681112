import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Modal.css';

function AlertConfigModal({ isOpen, onRequestClose, action, alertConfig, onClose, error, handleChangedAlertConfigCallback }) {
    const [editAlertConfig, setEditAlertConfig] = useState(alertConfig);

    useEffect(() => {
        Modal.setAppElement('#root');
    }, []); // Empty dependency array to run the effect only once when the component is mounted

    useEffect(() => {
        // Update the internal state when the editingAlertConfig prop changes
        setEditAlertConfig(alertConfig);
    }, [alertConfig]);

    return (
        <div>
            {/* Modal for adding or modifiyin AlertConfig */}
            <Modal
                isOpen={isOpen}
                onRequestClose={onRequestClose}
            >               

                <h3>Fill in the AlertConfig details to {action === "Add" ? "add:" : "modify"}</h3>

                <h4>{error !== null ? error : null}</h4>

                <label>Rule Name: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertNameUnique}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertNameUnique: e.target.value })}
                />
                <label>alertType: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertType}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertType: e.target.value })}
                />
                <label>Frequency In Min: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertFrequencyInMin}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertFrequencyInMin: e.target.value })}
                />
                <label>Min Msg Count: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertSendMaxMsgs}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertSendMaxMsgs: e.target.value })}
                />
                <label>Rule Start Time: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertStartTime}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertStartTime: e.target.value })}
                />

                <label>Rule End Time: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertEndTime}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertEndTime: e.target.value })}
                />

                <label>Query Filter: </label>
                <input
                    type="text"
                    value={editAlertConfig.alertFilter}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alertFilter: e.target.value })}
                />

                <label>Mail: Subject / TopDesk: Briefdescription </label>
                <input
                    type="text"
                    value={editAlertConfig.description}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, description: e.target.value })}
                />

                <label>alternative Body: </label>
                <input
                    type="text"
                    value={editAlertConfig.alternative}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, alternative: e.target.value })}
                />

                <label>Rule Last Run Time: </label>
                <input
                    type="text"
                    value={editAlertConfig.lastRun}
                    onChange={(e) => setEditAlertConfig({ ...editAlertConfig, lastRun: e.target.value })}
                />

                <button onClick={() => handleChangedAlertConfigCallback(editAlertConfig, action)}>{action === "Add" ? "Add New AlertConfig" : "Modify AlertConfig"}</button>
                <button class="cancel" onClick={onClose}>Cancel</button>
            </Modal>
        </div>
    );
};

export default AlertConfigModal;