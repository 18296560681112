import React, { useState } from 'react';
import './TokenManager.css';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import vkbeautify from 'vkbeautify';
import { getApiKey } from '../helpers/stringHelpers';

function TokenManager({ config }) {
    const { accounts, instance } = useMsal();
    const [formData, setFormData] = useState({
        client_id: '',
        client_secret: '',
        scope: '',
        grant_type: '',
    });

    const [tokenString, setTokenString] = useState('');
    const [showToken, setShowToken] = useState(false);

    const [tokenInfo, setTokenInfo] = useState(null);
    const [error, setError] = useState('');

    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const clipboardData = event.clipboardData.getData('text/plain');
        const pairs = clipboardData.split('&');

        const updatedFormData = { ...formData };

        pairs.forEach((pair) => {
            const [keyValuePair, ...rest] = pair.split('&');
            const key = keyValuePair.split('=')[0];
            const value = keyValuePair.slice(key.length + 1);

            // Check if the key exists in the form data
            if (formData.hasOwnProperty(key)) {
                // Sanitize the value to remove '\r' and '\n' characters
                const sanitizedValue = value.replace(/[\r\n]/g, '');
                updatedFormData[key] = sanitizedValue;
            } else {
                // If there's no matching key, paste the entire pair into the input
                if (event.target.tagName === 'INPUT' && event.target.type === 'text') {
                    const inputName = event.target.name;
                    updatedFormData[inputName] = keyValuePair;
                }
            }
        });

        setFormData(updatedFormData);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const applicationId = config.clientId;

        const accessTokenRequest = {
            scopes: ["api://" + applicationId + "/User.Read"],
            account: accounts[0],
        };
        instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                const logmsgApiKey = getApiKey(config.enviromentScope);

                let decodeClientId = decodeURIComponent(formData.client_id);
                let decodeClientSecret = decodeURIComponent(formData.client_secret);
                let decodeScope = decodeURIComponent(formData.scope);
                let decodeGrantType = decodeURIComponent(formData.grant_type);

                let body = encodeURI("client_id=" + decodeClientId +
                    "&client_secret=" + decodeClientSecret +
                    "&scope=" + decodeScope +
                    "&grant_type=" + decodeGrantType);

                let logmsgApiUrl = config.logMsgApiUrl;

                axios.post(logmsgApiUrl + "/MakeTokenV2", body, {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': 'text/plain',
                        'APIKEY': logmsgApiKey,
                    },
                })
                    .then(response => {
                        var token = response.data.access_token;
                        setTokenString(token);
                        setShowToken(true);
                    })
                    .catch(error => {
                        console.log(error)
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //Test Token String
    useEffect(() => {
        if (tokenString !== "") {
            try {
                const decodedToken = jwtDecode(tokenString);

                setTokenInfo(decodedToken);
                setError('');
                setShowToken(true);
            } catch (error) {
                setError("Invalid token");
                setTokenInfo(null);
                setShowToken(false);
            }
        } else {
            setError('');
            setTokenInfo(null);
            setShowToken(false);
        }
    }, [tokenString]);

    return (
        <div className="token-manager-container">
            <h2 className="token-manager-title">Token Manager</h2>
            <form onSubmit={handleSubmit} onPaste={handlePaste}>
                <div className="form-group">
                    <label htmlFor="client_id">Client Id:</label>
                    <input
                        type="text"
                        id="client_id"
                        name="client_id"
                        value={formData.client_id}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="client_secret">Client Secret:</label>
                    <input
                        type="text"
                        id="client_secret"
                        name="client_secret"
                        value={formData.client_secret}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="scope">Scope:</label>
                    <input
                        type="text"
                        id="scope"
                        name="scope"
                        value={formData.scope}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="grant_type">Grant Type:</label>
                    <input
                        type="text"
                        id="grant_type"
                        name="grant_type"
                        value={formData.grant_type}
                        onChange={handleInputChange}
                    />
                </div>
                <button className="button generate-button" type="submit">Generate Token</button>
            </form>

            {showToken && (
                <div className="token-container">
                    <p className="token-string">Access Token: {tokenString}</p>
                    <button className="button copy-button" onClick={() => copyToClipboard(tokenString)}>
                        <i className="fas fa-copy"></i> Copy to Clipboard
                    </button>
                </div>
            )}

            <div className="form-group">
                <label htmlFor="token_test">Test Token:</label>
                <input
                    type="text"
                    id="token_test"
                    name="token_test"
                    value={tokenString}
                    onChange={(e) => setTokenString(e.target.value)}
                />
            </div>

            {tokenInfo && (
                <div>
                    <h2>Token Info</h2>
                    <p><strong>Payload:</strong> {vkbeautify.json(JSON.stringify(tokenInfo))}</p>
                    <p><strong>Issued At:</strong> {new Date(tokenInfo.iat * 1000).toLocaleString()}</p>
                    <p><strong>Not Before:</strong> {new Date(tokenInfo.nbf * 1000).toLocaleString()}</p>
                    <p><strong>Expires At:</strong> {new Date(tokenInfo.exp * 1000).toLocaleString()}</p>
                </div>
            )}
            {error && <p>{error}</p>}
        </div>
    );
}

export default TokenManager;
