import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Profile from './components/Profile';
import Login from './components/Login';
import TokenManager from './components/TokenManager';
import MessageLogs from './components/MessageLogs';
import UniqErrorMsg from './components/UniqErrorMsg';
import ApiKeyManager from './components/ApiKeyManager';
import AlertLogs from './components/AlertLogs';
import AlertConfig from './components/AlertConfig';
import MessageReport from './components/MessageReport';
import LogoutModal from './components/Modal/LogoutModal';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { AppSettingsSchema } from './schemas/appSettingsSchema';
import axios from 'axios';
import Logout from './components/Logout';
function App() {
    const [config, setConfig] = useState(null);
    const [showLogoutAlert, setShowLogoutAlert] = useState(false);

    useEffect(() => {
        axios.get('/assets/appsetting.json')
            .then(response => {
                try {
                    const settings = AppSettingsSchema.parse(response.data);
                    setConfig(settings);
                } catch (error) {
                    console.error('Error parsing appsettings.json:', error);
                }
            })
            .catch(error => {
                console.error('Error loading appsettings.json:', error);
            });
    }, []);

    const userIsInRequiredGroups = async (instance, accounts, config) => {
        if (!accounts || accounts.length === 0) {
            return false; // User is not logged in
        }

        try {
            // Acquire a token with appropriate scopes to access Microsoft Graph API
            const tokenResponse = await instance.acquireTokenSilent({
                scopes: ['https://graph.microsoft.com/.default'],
                account: accounts[0], // Assuming you only have one account
            });

            // Parse the token's claims to check group memberships
            const accessToken = tokenResponse.accessToken;
            const response = await fetch('https://graph.microsoft.com/v1.0/me/memberOf', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();

            // Get the user's groups
            let userGroups = data.value.map(group => group.displayName);
            userGroups = userGroups + data.value.map(group => group.id);

            //Get accepted groups from config
            const acceptedGroups = config.authorisedGroups.split('|');

            //See if any of the user's groups are one of the accepted groups in config
            const found = acceptedGroups.some(acceptedGroup => userGroups.includes(acceptedGroup));
            return found;
        } catch (error) {
            console.error('Error checking group membership:', error);
            return false; // Error occurred
        }
    };

    const handleLogoutConfirmation = () => {
        setShowLogoutAlert(false); // Hide logout alert
    };

    const PrivateRoute = ({ component: Component, path, config, ...rest }) => {
        const { accounts, instance, inProgress } = useMsal();
        const navigate = useNavigate();
        const [loading, setLoading] = useState(true);
        const [userInRequiredGroups, setUserInRequiredGroups] = useState(false);
        const loggingOutRef = useRef(false);  // Use ref to track logging out status

        useEffect(() => {
            const handleRedirect = async () => {
                if (inProgress === "none") {
                    if (accounts.length === 0) {
                        const returnUrl = path;
                        // User is not authenticated, redirect to the login page with returnUrl
                        const loginUrl = `/login?returnUrl=${encodeURIComponent(returnUrl)}`;
                        navigate(loginUrl, { replace: true });
                    }
                }
            };

            handleRedirect();
        }, [accounts, inProgress, navigate]);

        useEffect(() => {
            const checkUserGroups = async () => {
                try {
                    const result = await userIsInRequiredGroups(instance, accounts, config);
                    setUserInRequiredGroups(result);
                } catch (error) {
                    setUserInRequiredGroups(false);
                } finally {
                    setLoading(false);
                }
            };

            checkUserGroups();
        }, [instance, accounts, config]);

        if (loading) {
            return <div>Loading...</div>;
        }

        const handleLogout = async () => {
            try {
                await instance.logoutRedirect();
            } catch (error) {
            }
        };

        if (accounts.length > 0 && inProgress === "none" && userInRequiredGroups) {
            return <Component {...rest} config={config} />;
        } else if (accounts.length > 0 && inProgress === "none" && !userInRequiredGroups) {
            if (!loggingOutRef.current) {
                loggingOutRef.current = true; // Set the ref to true to prevent multiple logouts
                alert("You do not have sufficient permissions. You will be logged out.");
                handleLogout();
            }
            return null;
        } else {
            return null;
        }
    };
    
    if (config === null) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Router>
                <div>
                    <Navbar
                        config={config}
                    />
                    {showLogoutAlert && (
                        <LogoutModal onClose={() => setShowLogoutAlert(false)}>
                            <p>Access has not been granted because this user does not have one of the valid AD Groups.</p>
                            <button onClick={handleLogoutConfirmation}>Logout</button>
                        </LogoutModal>
                    )}
                    <Routes>
                        <Route path="/" element={
                            <PrivateRoute path="/messagelogs" component={MessageLogs} config={config} />} />

                        <Route path="/profile" element={
                            <PrivateRoute path="/profile" component={Profile} config={config} />} />
                        <Route path="/messagelogs" element={
                            <PrivateRoute path="/messagelogs" component={MessageLogs} config={config} />} />
                        <Route path="/uniqerrormsg" element={
                            <PrivateRoute path="/uniqerrormsg" component={UniqErrorMsg} config={config} />} />
                        <Route path="/apikeymanager" element={
                            <PrivateRoute path="/apikeymanager" component={ApiKeyManager} config={config} />} />
                        <Route path="/alertlogs" element={
                            <PrivateRoute path="/alertlogs" component={AlertLogs} config={config} />} />
                        <Route path="/alertconfig" element={
                            <PrivateRoute path="/alertconfig" component={AlertConfig} config={config} />} />
                        <Route path="/messagereport" element={
                            <PrivateRoute path="/messagereport" component={MessageReport} config={config} />} />
                        <Route path="/tokenmanager" element={
                            <PrivateRoute path="/tokenmanager" component={TokenManager} config={config} />} />

                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                    </Routes>
                </div>
            </Router>
        </div>
    );
}

export default App;
