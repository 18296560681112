import React from 'react';
import Modal from 'react-modal';
import './Modal.css';

const LogoutModal = ({ onClose, children }) => {
    return (
        <Modal
            isOpen={true} 
            onRequestClose={onClose}
            className="logout-modal"
            overlayClassName="logout-overlay"
        >
            <div className="modal-content">{children}</div>
        </Modal>
    );
};

export default LogoutModal;
