import React from 'react';
import TextFilter from './TextFilter';
import DateFilter from './DateFilter';

const FilterBar = ({ config, inputValue, handleInputChange, handleInputKeyPress, handleSearchClick, dateFrom, handleDateFromChange, toggleDatePicker, dateTo, handleDateToChange }) => {
    return (
        <div className="filter-container" style={{ backgroundColor: config.color }}>
            <TextFilter
                inputValue={inputValue}
                handleInputChange={handleInputChange}
                handleInputKeyPress={handleInputKeyPress}
                handleSearchClick={handleSearchClick}
            />
            <DateFilter
                selectedDate={dateFrom}
                handleDateChange={handleDateFromChange}
                toggleDatePicker={toggleDatePicker}
                label="From Date"
            />
            <DateFilter
                selectedDate={dateTo}
                handleDateChange={handleDateToChange}
                toggleDatePicker={toggleDatePicker}
                label="To Date"
            />

        </div>
    );
};

export default FilterBar;