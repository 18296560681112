import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const TextFilter = ({ inputValue, handleInputChange, handleInputKeyPress, handleSearchClick }) => {
    return (
        <div className="filter-item">
            <label htmlFor="text-filter">Text Filter:</label>
            <div>
                <input
                    className="filter-input"
                    type="text"
                    id="text-filter"
                    placeholder="Search..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleInputKeyPress}
                />
                <FontAwesomeIcon
                    className="filter-icon"
                    icon={faMagnifyingGlass}
                    onClick={handleSearchClick}
                />
            </div>
        </div>
    );
};

export default TextFilter;
