import * as vkbeautify from 'vkbeautify';
import axios from 'axios';
import { getApiKey } from './stringHelpers';

export const viewFile = (id, fileName, config, accounts, instance) => {
    const applicationId = config.clientId;
    const apiKey = getApiKey(config.enviromentScope);

    const accessTokenRequest = {
        scopes: ["api://" + applicationId + "/User.Read"],
        account: accounts[0],
    };
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            const logmsgApiUrl = config.logMsgApiUrl;

            let getRequest = logmsgApiUrl + '/LogsGetId?ID=' + id;

            // Setup Header
            const headers = {
                'Authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKEY': apiKey
            };

            // Make the API request
            axios.get(getRequest, {
                headers: headers
            })
                .then((response) => {
                    let dataType = 'text/plain;charset=UTF-8';
                    let binaryData = [];

                    if (response.data.bodyContent != null && response.data.bodyContent != '') {
                        let decodedContent = decodeURIComponent(escape(atob(response.data.bodyContent)));
                        // Converts XML into a human readable format (pretty print) while respecting the xml:space attribute.
                        if (fileName != null) {
                            if (fileName.endsWith('.xml'))
                                vkbeautify.xml(decodedContent);
                            if (fileName.endsWith('.json'))
                                vkbeautify.json(decodedContent);
                            if (fileName.endsWith('.css'))
                                vkbeautify.css(decodedContent);
                            if (fileName.endsWith('.sql'))
                                vkbeautify.sql(decodedContent);
                        }

                        binaryData.push(decodedContent);

                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                        window.open(downloadLink.href, fileName);
                    } else {
                        window.alert("This log record has no bodyContent.");
                    }
                })
                .catch((error) => {
                    // Handle errors
                    console.log(error);
                });
        })
        .catch((error) => {
            console.log(error);
        });
}

export const downloadFile = (id, fileName, config, accounts, instance) => {
    const applicationId = config.clientId;
    const apiKey = getApiKey(config.enviromentScope);

    const accessTokenRequest = {
        scopes: ["api://" + applicationId + "/User.Read"],
        account: accounts[0],
    };
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            const logmsgApiUrl = config.logMsgApiUrl;

            let getRequest = logmsgApiUrl + '/LogsGetId?ID=' + id;

            // Setup Header
            const headers = {
                'Authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKEY': apiKey
            };

            // Make the API request
            axios.get(getRequest, {
                headers: headers
            })
                .then((response) => {
                    let dataType = 'text/plain;charset=UTF-8';
                    let binaryData = [];

                    if (response.data.bodyContent != null && response.data.bodyContent != '') {
                        let decodedContent = decodeURIComponent(escape(atob(response.data.bodyContent)));
                        // Do NOT format the xml when downloading it
                        binaryData.push(decodedContent);
                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                        if (fileName != null && fileName != '')
                            downloadLink.setAttribute('download', fileName);
                        else
                            downloadLink.setAttribute('download', "download.txt");
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                    }
                    else {
                        window.alert("This log record has no bodyContent.");
                    }
                })
                .catch((error) => {
                    // Handle errors
                    console.log(error);
                });
        })
        .catch((error) => {
            console.log(error);
        });
}

export const verifyCanResendFile = (row, config, openModal, onResendFile, accounts, instance) => {
    const applicationId = config.clientId;
    const apiKey = getApiKey(config.enviromentScope);

    const accessTokenRequest = {
        scopes: ["api://" + applicationId + "/User.Read"],
        account: accounts[0],
    };
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            const logmsgApiUrl = config.logMsgApiUrl;

            let getRequest = logmsgApiUrl + '/canresendmsg?ID=' + row.id;

            // Setup Header
            const headers = {
                'Authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKEY': apiKey
            };

            // Make the API request
            axios.get(getRequest, {
                headers: headers
            })
                .then((response) => {
                    //Open modal with confirm and cancel button
                    openModal();
                    const modalData = {
                        ID: row.id,
                        TextTitle: 'Resend Data',
                        TextBody1: 'Do you want to resend the data with ID: ' + row.id + ' For: ' + row.user,
                        TextBody2: 'Sender: ' + row.sender,
                        TextBody3: 'Receiver: ' + row.receiver,
                        TextBody4: 'Type: ' + row.transactionType,
                        TextBody5: 'Description: ' + row.description
                    }
                    onResendFile(modalData);
                })
                .catch((error) => {
                    // Handle errors
                    console.log(error);
                    window.alert("Cannot resend this message");
                });
        })
        .catch((error) => {
            console.log(error);
        });
}

export const resendFile = (modalData, config, accounts, instance) => {
    const applicationId = config.clientId;
    const apiKey = getApiKey(config.enviromentScope);

    if (modalData) {

    const accessTokenRequest = {
        scopes: ["api://" + applicationId + "/User.Read"],
        account: accounts[0],
    };
    instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            const logmsgApiUrl = config.logMsgApiUrl;

            let postRequest = logmsgApiUrl + '/LogResend?ID=' + modalData.ID;

            // Setup Header
            const headers = {
                'Authorization': 'Bearer ' + accessToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKEY': apiKey
            };

            // Make the API request
            axios.post(postRequest, null, {
                headers: headers
            })
                .then(response => {
                    if (response.data === "Ok") {
                        window.alert("Data (re)sendt OK.");
                    }
                    console.log(response);
                })
                .catch(error => {
                    window.alert("Error: POST /LogResend: " + error);
                });
        })
        .catch((error) => {
            console.log(error);
        });
    } else {
        window.alert("Resend Data Canceled. Missing ID");
    }
}
